import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/header"
import Why from "../components/why"
import Cta from "../components/cta"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <Header />
    <section className="about">
      <div className="container">
        <div className="grid">
          <div className="column-xs-12">
            <h4>Experienced and Trusted Painting Company</h4>
            <h2>Luis's Painting is a locally owned, licensed, and full service painting company with more than 20 years of experience serving New Jersey, New York, and Indiana areas.</h2>
            <p>Today, Luis's Painting is one of the most long-running painting companies in the area. We are proud of the many years of experience we have in the painting industry. Luis's Painting continues to provide high quality services at reasonable rates. Luis’s Painting promises to offer you with quick, dependable, and skilled services.</p>
            <Link to='/about' className="button" role="button">About Our Company <span className="icon arrow" aria-hidden="true"></span></Link>
          </div>
        </div>
      </div>
    </section>
    <Why />
    <Cta />
  </Layout>
)

export default IndexPage
