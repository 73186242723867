import { Link } from "gatsby"
import React from "react"

const Header = () => (
  <header className="intro">
    <div className="container">
      <div className="box">
        <h4>Welcome to Luis's Painting</h4>
        <h1>Professional painting and handyman services for your home or business.</h1>
        <Link to='/contact' className="button" role="button">Get a Free Estimate <span className="icon arrow" aria-hidden="true"></span></Link>
      </div>
    </div>
  </header>
)

export default Header
